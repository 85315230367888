

.list-item {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 24px;
  font-weight: 600;
  color: white;
}

.header {
  font-size: 60px;
  font-weight: 600;
  background-image: linear-gradient(to left, #553c9a, #b393d3);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}



/* CSS */
.button-36 {
  background-image: linear-gradient(92.88deg, #455EB5 9.16%, #5643CC 43.89%, #673FD7 64.72%);
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  flex-shrink: 0;
  font-family: "Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 2rem;
  padding: 0 1.6rem;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
  transition: all .5s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-36:hover {
  box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;
  transition-duration: .1s;
}




.button-37 {
  background-image: linear-gradient(92.88deg, #45b55a 9.16%, #43cc63 43.89%, #49d73f 64.72%);
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  flex-shrink: 0;
  font-family: "Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
  font-size: 12px;
  font-weight: 500;
  height: 2rem;
  /* padding: 0 1.6rem; */
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
  transition: all .5s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 4rem;
}

.button-37:hover {
  box-shadow: rgba(63, 205, 98, 0.5) 0 1px 30px;
  transition-duration: .1s;
}



.input-task {
  width: 10rem;
  background-color: transparent;
  border-radius: 15px;
  border: 2px solid rgba(80, 63, 205, 0.5);
  padding: 5px;
  color: white;
}

.input-task::placeholder {
  color: gray;
}

.input-task:focus {
  outline: rgba(80, 63, 205, 0.5);;
}

.form {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.button-38{
  background-image: linear-gradient(92.88deg, #b5455a 9.16%, #cc4357 43.89%, #d73f51 64.72%);
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  flex-shrink: 0;
  font-family: "Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
  font-size: 12px;
  font-weight: 500;
  height: 2rem;
  /* padding: 0 1.6rem; */
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
  transition: all .5s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 4rem;
}

.button-38:hover {
  box-shadow: rgba(205, 63, 91, 0.5) 0 1px 30px;
  transition-duration: .1s;
}

